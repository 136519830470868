import React, { Component }  from 'react';
import { Form, useLoaderData } from "react-router-dom";
import { getEpisode } from "../episodes";
import { ShortList } from "../shorts";
import axios from "axios";


export async function loader({ params }) {
    // return getEpisode(params.episodeId);

    return fetch("https://d3n3zjlar0kbda.cloudfront.net/Episodes?"+ new URLSearchParams({filterByFormula: "{episodeId}=" + params.episodeId}),
)

    // axios({
    //     method: "get",
    //     url:
    //         "https://d3n3zjlar0kbda.cloudfront.net/Episodes/",
    //     headers: {},
    //     params: { filterByFormula: "{episodeId}=" + params.episodeId}
    //   }).then(res => {
    //        console.log("Episodes: " + res);
    //       //setTimeout(() => {  setEpisodeList(res.data.records); }, 5000);
    //       return res.data.records.fields;
    //   });
    // // return {}
  }
  
export default function Episode() {
const episode = useLoaderData().records[0].fields;
console.log(episode)
// console.log(episode[0].fields);

return(
    <div id='episode' className="App-header">
        <ShortList seriesId={episode.Series[0]} episodeId={episode.episodeId} seriesName={episode.SeriesName} episodeName={episode.Episode} SeriesImageUrl={episode.SeriesImageUrl[0]} Duration={episode.Duration} audioUrl={episode.AudioUrl}/>
    </div>
)
// existing code
}