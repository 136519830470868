import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import styled from "styled-components";


export default function SubscribeEpisode({ seriesId }) {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false)

    function handleSubscribeEpisode(email) {

        const data =
        {
            "records": [
                {
                    "fields": {
                        "email": email,
                        "seriesId": [seriesId]
                    }
                }
            ]
        }

        axios({
            method: "post",
            url:
                "https://2f7ii2k6w8.execute-api.us-east-1.amazonaws.com/teststage",
            data,
            params: {}
        }).then(res => {
            console.log(res)
        });
    }

    function handleChange(event) {
        // let input = input;
        // input[event.target.name] = event.target.value;
        setEmail(event.target.value)
        //handleSubscribe(event.target.value, selectedCategory)
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (validate() && !isSubscribed) {
            console.log("valid email");
            //handleSubscribe(buildSubscription(selectedCategory,email,frequency,TduCompanyName))
            handleSubscribeEpisode(email)
            setIsSubscribed(true)
            // let input = {};
            // input["name"] = "";
            // input["email"] = "";
            // input["comment"] = "";
            //setEmail(email)
        }
    }

    function validate() {
        // let input = input
        let errors = {};
        let isValid = true;

        // if (!input["email"]) {
        //   isValid = false;
        //   errors["email"] = "Please enter your email Address.";
        // }

        if (typeof email !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        setErrors(errors)

        return isValid;
    }

    return (
        <SubscribeContainer>
            <div>
                Notify me of new episodes:
            </div>
            <form>
                <InputContainer>
                    <input
                        name="email"
                        value={email}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="Email"
                        id="email" />
                        <ButtonContainer>
                        {isSubscribed ? <SubscribedPlaceholder>Oh Snap! Get Ready!</SubscribedPlaceholder> :
                        <SubmitButton onClick={handleSubmit}>Subscribe</SubmitButton>
                    }
                    </ButtonContainer>
                </InputContainer>
            </form>
        </SubscribeContainer>

    )
}

const ButtonContainer = styled.div`
display: flex;
flex-flow: column;
align-items: center;
`

const SubscribeContainer = styled.div`
display: flex;
flex-flow: column;
align-items: center;
padding-bottom: 80px;
`

const SubscribedPlaceholder = styled.div`
color:white;
`

const InputContainer = styled.div`

input{ 
    font-size: 18px;
    padding: 10px;
    margin: 6px;
    border-radius: 5px;
}
`


const SubmitButton = styled.div`
height:30px;
text-align: center;
border-radius: 5px;
background-color: navy;
color:white;
display: flex;
align-items: center;
cursor:pointer;
inline-size: fit-content;
padding: 5px 20px;
margin: 5px;
`
