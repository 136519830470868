import React, { Component }  from 'react';
import { getShorts } from "../episodes";
import styled from "styled-components";
import EarlyAccess from '../earlyaccess';

export async function loader() {
    //const shorts = await getShorts();
    return null;
    //return { shorts };
  }

export default function Root() {
    return (
        <RootContainer>
            <LogoContainer>
            <Logo src="podsnaps.png"></Logo>
                <h2>Bite sized clips from your favorite podcasts.</h2>
            </LogoContainer>
            <EarlyAccess/>
        </RootContainer>
    );
  }

const Logo = styled.img `
max-width: 120px;
`

const LogoContainer = styled.div`
display: flex;
flex-flow: column;
flex-direction: column;
align-items: center;
h2{
  padding: 20px;
    max-width: 380px;
}
`

const RootContainer = styled.div`
background-color: #282c34;
min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`