import React, {useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import { PostList } from "./Post"
// import { ShortList } from "./Shorts"
import './App.css';
import axios from "axios";
import styled from "styled-components";
import ReactGA from "react-ga4";
// import TagManager from 'react-gtm-module'
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Root, { loader as rootLoader } from "./routes/root";
import Episode, {loader as episodeLoader} from './routes/episode';

import { useSwipeable, Swipeable } from 'react-swipeable'
//keyMKczMgt4FWjB0E

// const tagManagerArgs = {
//   gtmId: 'GTM-5QCDVP5'
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader
  },{

    path: "huberman/:episodeId",
    element: <Episode />,
    loader: episodeLoader,
  }
]);


// TagManager.initialize(tagManagerArgs)

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

function App() {
  ReactGA.initialize("G-YMSLKQ0YTR");
  // const [renderSplashscreen, setSplash] = useState(false);
  const [episodeList, setEpisodeList] = useState(null)
  const [index, setIndex] = useState(0)
  // const [time, setTime] = useState(new Date().toLocaleTimeString());
  // const secondsPassed = useRef(0);
  
  const handlers = useSwipeable({ onSwipedLeft: () => {
    
    handleLeft();
  },
  onSwipedRight: () => {
    
    handleRight();
  },
  delta:100,
  trackMouse:true
  })

  const handleLeft = () => {
    console.log("left")
    window.scrollTo(0, 0)
    if(index === 0) setIndex(episodeList.length-1)
    else setIndex(index-1)
  }
  const handleRight = () => {
    console.log("right")
    window.scrollTo(0, 0)
    if(index === episodeList.length-1) setIndex(0)
    else setIndex(index+1)
  }
  
  /**
  React.useEffect(() => {
    axios({
      method: "get",
      url:
          "https://d3n3zjlar0kbda.cloudfront.net/Episodes?maxRecords=3&view=Primary",
      headers: {}
    }).then(res => {
        // console.log("Episodes: " + res.data.records);
        //setTimeout(() => {  setEpisodeList(res.data.records); }, 5000);
        
    });
  }, []); // <-- Have to pass in [] here!
*/

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     const date = new Date()
  //     secondsPassed.current = secondsPassed.current + 1;
  //     setTime(date.toLocaleTimeString());
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, [time]);

  // if (episodeList === null || secondsPassed.current < 5)
  if (episodeList === null)
  return(
    <div></div>
    
    // <Splash>
    // <Title>PodSnaps</Title>
    // <img src={'podsnaps.png'} />
    // <SubTitle>Bite sized highlights from your favorite podcasts</SubTitle>
    // </Splash>
  )
  console.log(episodeList)
  const episode = episodeList[index].fields

    // return(
      //     <ShortList episodeId={episode.episodeId} seriesName={episode.SeriesName} episodeName={episode.Episode} SeriesImageUrl={episode.SeriesImageUrl[0]} Duration={episode.Duration} audioUrl={episode.AudioUrl}/>
    //   <div className="App" {...handlers} >
    //   <header className="App-header">  
    //   </header>
    // </div>
    // )



      
// if(renderSplashscreen)
//       return <SplashScreen/>;
//       else
//   return (
//     /*{...handlers}*/
//     <div className="App" >
//       <header className="App-header">
//         {/*<PostList/>*/}
//           <ShortList />
//       </header>
//     </div>
//   );
}

export default App;

const Title = styled.h1`
padding: 50px;
`
const SubTitle = styled.h3`
padding: 0 30px;
`

const Splash = styled.div`
background: -webkit-linear-gradient(left, #25c481, #25b7c4);
    background: linear-gradient(to right, #25c481, #25b7c4);
   height: 100vh;
img {
  // width: 100vw;
  height: 100px;
  padding: 50px;
}
`

/**
 * 
 * 

class SplashScreen extends React.Component {
  render() {
    const style = {top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      position: 'fixed'};

    return (
      <img src={'podsnaps.png'} style={style}/>
    );
  }
}


 */