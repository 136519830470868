import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from "styled-components";
import axios from "axios";
import { BsReplyFill } from 'react-icons/bs';

export class PostList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postId: 3,
            replies: [
            ]
        }

        this.handleNewPost = this.handleNewPost.bind(this);
        this.handleReplyPost = this.handleReplyPost.bind(this);

    }
    componentDidMount() {
        // this.setState(
        //     {
        //             postId: uuidv4(),
        //             parentId: null,
        //             title: "post1",
        //             message: "lorem ipsum",
        //             upvote: 0,
        //             downvote: 0,
        //             left: 0,
        //             right: 0

        //     }
        // )
        this.getPosts(this.state.postId)
    }


    handleNewPost() {
        var uuid = uuidv4();
        this.setState({
            replies: this.state.replies.push({ postId: uuid, parentId: uuid })
        })
    }

    handleReplyPost(postId, parentId) {
        // this.setState({
        //     replies: this.state.replies.concat({postId:postId,parentId:parentId, title:postId})
        // })
        axios({
            method: "post",
            url:
                "https://zzfrsv9pih.execute-api.us-east-1.amazonaws.com/dev/todos",
            data: { message: "test message", parentId: parentId },
            params: {}
        }).then(res => {
            console.log(res)
            // this.setState({replies: this.state.replies.concat({postId:res.postId ,parentId:parentId, title:postId})});
            this.getPosts(this.state.postId)
        });
        // console.log(list_to_tree(this.state.posts))
    }

    getPosts(postId) {
        axios({
            method: "get",
            url:
                "https://zzfrsv9pih.execute-api.us-east-1.amazonaws.com/dev/todos",
            params: { parentId: 2 }
        }).then(res => {
            this.setState({ replies: res.data });
        });
    }


    render() {

        return (
            <div>
                <PostContainer key={this.state.postId} postId={this.state.postId} posts={this.state.replies} handleReplyPost={this.handleReplyPost} />
                {/*<PostContainer key={this.state.postId} postId={this.state.postId} posts={this.state.replies} handleReplyPost={this.handleReplyPost}/>*/}
            </div>

        )
    }

}

export class PostContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            children: []
        }
        this.handleReplyPost = this.handleReplyPost.bind(this);
        this.handleUpvote = this.handleUpvote.bind(this);
        this.handleDownvote = this.handleDownvote.bind(this);
    }

    handleReplyPost() {
        var uuid = uuidv4();
        this.props.handleReplyPost(uuid, this.props.postId)
    }
    handleUpvote() {
        this.setState(prevState => ({
            upvote: prevState.upvote + 1
        }))
    }


    handleDownvote() {
        this.setState(prevState => ({
            downvote: prevState.downvote + 1
        }))
    }

    render() {
        var children = this.props.posts.filter(({ parentId }) => parentId === this.props.postId)

        const postChildren = children != undefined ? children.map((post, index) => (
            <PostContainer key={post.postId} posts={this.props.posts} postId={post.postId} handleReplyPost={this.props.handleReplyPost} />
        )) : null
        //const post = props.posts.filter( post=> post.postId === this.props.postId)

        return (
            <PostContainerStyled>
                <PostCard postId={this.props.postId} handleDownvote={this.handleDownvote} handleUpvote={this.handleUpvote} handleReplyPost={this.handleReplyPost} />
                {postChildren}
            </PostContainerStyled>
        )
    }
}

function PostCard(props) {
    return (
        <PostCardStyled>
            <PostHeader>
            {props.postId}
            </PostHeader>
            {/**
                <PostMessage>{props.post.message}</PostMessage>
                <div onClick={props.handleUpvote}>Up</div>
                <div onClick={props.handleDownvote}>Down</div>
                <div>{props.post.upvote-props.post.downvote}</div>
            */}
            <PostMessage>
            Message
            </PostMessage>
            <PostFooterContainer>
                <PostCurate>
                    <div onClick={props.handleReplyPost}>Upvote</div>
                </PostCurate>
                <PostResponse>
                    <div onClick={props.handleReplyPost}><BsReplyFill /></div>
                </PostResponse>
            </PostFooterContainer>
        </PostCardStyled>
    )
}

const PostHeader = styled.div`
grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 2;
`;

const PostMessage = styled.div`
grid-row-start: 2;
grid-row-end: 3;
grid-column-start: 1;
grid-column-end: 2;
`;

const PostFooterContainer = styled.div`
    display: grid;
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
`

const PostCurate = styled.div`
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
`
const PostResponse = styled.div`
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
`

const PostContainerStyled = styled.div`
border-left: 1px solid #4284f4;
margin-left: 5px;
`


const PostCardStyled = styled.div`
display: grid;
grid-template-columns: auto;
grid-gap: 10px;
background-color: #2196F3;
padding: 10px;
`