import React, { Component,useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import styled from "styled-components";
import axios from "axios";
import { BsReplyFill, BsPlay, BsStop } from 'react-icons/bs';
import { GoQuote, GoLightBulb, GoQuestion } from 'react-icons/go';
import ReactGA from "react-ga4";
import SubscribeEpisode from "./subscribe";

// import {MDReactComponent} from 'markdown-react-js';
// import Ticker from 'react-ticker'

// export async function getShorts(episodeId) {
//     axios({
//         method: "get",
//         url:
//             "https://d3n3zjlar0kbda.cloudfront.net/Snaps/",
//         headers: {},
//         params: { filterByFormula: "{episodeId}=" + episodeId}
//     }).then(res => {
//         console.log(res)
//         return res.data.records;
//     });
//     return {};
// }

export class ShortList extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            shorts: null,
            // [
            //     {
            //         shortId: uuidv4(),
            //         type: "Insight",
            //         offset: {
            //             hour:0,
            //             minute:2,
            //             second:45
            //         },
            //         title: "I took sharktank seriously",
            //         text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            //     },
            //     {
            //         shortId: uuidv4(),
            //         type: "Question",
            //         offset: {
            //             hour:0,
            //             minute:9,
            //             second:35
            //         },
            //         title: "This is a big f'ing deal!",
            //         text:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            //     }
            // ],
            // series_title:"HIBT",
            // episode_title:"Episode One",
            isPlaying : false,
            sectionPlaying: null,
            currentPosition: null,
            loading: null
        }

        this.playAudio = this.playAudio.bind(this);
        this.stopAudio = this.stopAudio.bind(this);
        this.handleLoaded = this.handleLoaded.bind(this);
        this.handlePlaying = this.handlePlaying.bind(this);

    }
    componentDidMount() {
        // this.setState(
           
            // {
            //         ShortId: uuidv4(),
            //         parentId: null,
            //         title: "Short1",
            //         message: "lorem ipsum",
            //         upvote: 0,
            //         downvote: 0,
            //         left: 0,
            //         right: 0

            // }
        // )
        this.getShorts(this.props.episodeId)
        
        // console.log(this.state)
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.episodeId !== prevProps.episodeId) {
            this.getShorts(this.props.episodeId)
            const audioEl = document.getElementsByClassName(this.props.episodeId)[0]
            audioEl.load()
            this.setState({loading:true})
        }
      }

      handlePlaying(){
          if(this.state.isPlaying===false)
          this.setState({isPlaying:true, loading:false})
      }

      handleLoaded (){
        if(this.state.loading===true || this.state.loading===null){
            console.log('Loaded data');
            if(this.state.sectionPlaying!==null){
                var short = this.state.shorts.filter(({id}) => id === this.state.sectionPlaying)[0]
                const playAt = short.fields.Start
                const audioEl = document.getElementsByClassName(this.props.episodeId)[0]
                audioEl.currentTime = playAt
            }
            this.setState({loading: false})
        } 
      }

    stopAudio() {
        const audioEl = document.getElementsByClassName(this.props.episodeId)[0]
        audioEl.pause()
        this.setState({isPlaying:false,sectionPlaying:null})
    }
    
    playAudio(section) {


        ReactGA.event({
            category: "shorts",
            action: "play short"
          });

        // console.log(section)
        var short = this.state.shorts.filter(({id}) => id === section)[0]
        // console.log(short)
        const audioEl = document.getElementsByClassName(this.props.episodeId)[0]
        // audioEl.load()
        // const playAt = offset.hour*60*60+offset.minute*60+offset.second
        const playAt = short.fields.Start
        audioEl.currentTime = playAt
        audioEl.play()
        this.setState({sectionPlaying:section})
      }


    getShorts(episodeId) {
        axios({
            method: "get",
            url:
                "https://d3n3zjlar0kbda.cloudfront.net/Snaps/",
            headers: {},
            params: { filterByFormula: "{episodeId}=" + episodeId}
        }).then(res => {
            // console.log(res)
            this.setState({ shorts: res.data.records });
        });
    }


    render() {
        if (this.state.shorts === null) return (<div></div>)
        const audioUrl = this.props.audioUrl
        const sortedShorts = this.state.shorts.sort(function(a,b){
            var keyA = a.fields.Start;
            var keyB = b.fields.Start;
            // Compare the 2 dates
            if(keyA < keyB) return -1;
            if(keyA > keyB) return 1;
            return 0;
        })
        const shorts = sortedShorts.map((short,index) => (
            <div key={short.id} >
            <Item short={short.fields} id={short.id} playAudio={this.playAudio} stopAudio={this.stopAudio} sectionPlaying={this.state.sectionPlaying} loading={this.state.loading}/>
            <Spacer/>
            </div>
        ))
        return (
            <PodContainer>
            <audio className={this.props.episodeId} preload="none" onCanPlay={this.handleLoaded} onPlaying={this.handlePlaying}>
                 <source src={audioUrl}></source>
            </audio>
                <Header series_title={this.props.seriesName} episode_title={this.props.episodeName} SeriesImageUrl={this.props.SeriesImageUrl} Duration={this.props.Duration}/>
                {/*<ShortContainer shorts={this.state.shorts} playAudio={this.playAudio} stopAudio={this.stopAudio} sectionPlaying={this.state.sectionPlaying}/>*/}
                <ShortContainerStyled>
                {shorts}
                </ShortContainerStyled>
                <SubscribeEpisode seriesId={this.props.seriesId}/>
            </PodContainer>

        )
    }

}
const PodContainer=styled.div`
max-width: 480px;
`



function Header(props){
    const [isSticky, setSticky] = useState(false);
    const [isScrolled, setScrolled] = useState(false);
    const [scrollPos, setScrollPos] = useState(null);
    const [playPos, setPlayPos] = useState(null);
    const ref = useRef(null);
    
    const handleScroll = () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 0);
        // setScrollPos(window.scrollY)
        if(!isScrolled)
            setScrolled(window.scrollY >10)
      }
      requestAnimationFrame(() => {
        // Calculates the scroll distance
        calculateScrollDistance();
      });
    };

    // const handleTimeUpdate = () => {
    //     console.log(this.audioEl.currentTime)
    //     setPlayPos(this.audioEl.currentTime)
    // }

    const calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const docHeight = getDocHeight();

        const  totalDocScrollLength  =  docHeight  -  windowHeight;
        const  scrollPostion  =  Math.floor(scrollTop  /  totalDocScrollLength  *  100)
        setScrollPos(scrollPostion)
      }

    const getDocHeight  =  ()  =>  {
        return Math.max(
          document.body.scrollHeight,  document.documentElement.scrollHeight,
          document.body.offsetHeight,  document.documentElement.offsetHeight,
          document.body.clientHeight,  document.documentElement.clientHeight
        );
      }

    // const audioEl = document.getElementsByClassName("sample")[0]
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // this.audioEl.addEventListener('timeupdate', handleTimeUpdate);
    
        return () => {
          window.removeEventListener('scroll', () => handleScroll);
        //   this.audioEl.removeEventListener('timeupdate', () => handleTimeUpdate);
        };
      }, []);

    return(
        <div className={`sticky-wrapper${isScrolled ? ' sticky small' : ' sticky'}`} ref={ref}>
        <HeaderContainerStyled className="sticky-inner">
            <SeriesImage isScrolled={isScrolled}><img src={props.SeriesImageUrl}/></SeriesImage>
            <DetailsContainer>
                <SeriesTitle><span>{props.series_title}</span></SeriesTitle>
                <EpisodeTitle><span>{props.episode_title}</span></EpisodeTitle>
                <PlayerDetails><Duration duration={props.Duration}/></PlayerDetails>
            </DetailsContainer>
            <HeaderScroller class="progress-container" scroll={scrollPos}/>
        </HeaderContainerStyled>
        </div>
    )
}
//This made it flicker on iOS - isSticky is not needed
//<div className={`sticky-wrapper${isSticky ? (isScrolled ? ' sticky small' : ' sticky') : ''}`} ref={ref}>

function Duration(props){
    const duration = props.duration
    if (duration){

        var hours = Math.floor(duration/3600)
        var minutes = Math.floor((duration-(hours*3600))/60)
        var hours_string = ""
        var minutes_string = ""
        if (hours>1) hours_string = hours+" hrs"
        else if (hours===1) hours_string = hours+" hr "
        else hours_string = ""

        if (minutes>1) minutes_string = minutes+" mins"
        else if (minutes===1) minutes_string = minutes+" min"
        else minutes_string = ""

        if (hours===0 && minutes===0) minutes_string="< 1 min"

        return hours_string+minutes_string
    }
    return ""
}
const DetailsContainer = styled.div`
grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 2;
grid-column-end: 2;
display: grid;
grid-auto-rows: 33%;
font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Arial, sans-serif;
}

`
const SeriesImage = styled.div`
grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 2;

width: fit-content;
text-align: center;
margin: ${props=>props.isScrolled ? "0rem 1rem 0rem 0rem":"1rem"};
transition: margin 0.5s;

img{
    float: left;
    width: auto;
    height: ${props=>props.isScrolled ? "5rem" : "6rem"};
    transition: height 0.5s;
}
`

const SeriesTitle = styled.div`
grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 1;
align-self: end;
text-align: left;
font-weight: bold;
span {

}
`
const EpisodeTitle = styled.div`
grid-row-start: 2;
grid-row-end: 3;
grid-column-start: 1;
grid-column-end: 1;
align-self: end;
text-align: left;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
max-width: 100%;
span {

}
`
const PlayerDetails = styled.div`
grid-row-start: 3;
grid-row-end: 4;
grid-column-start: 1;
grid-column-end: 1;
align-self: end;
text-align: right;
font-size : small;

span {

}
`
const HeaderScroller = styled.div`
grid-row-start: 2;
grid-row-end: 3;
grid-column-start: 1;
grid-column-end: 3;
background:  linear-gradient(
    to right,
    rgba(250, 224, 66, .8) ${props => props.scroll}%,
    transparent  0);
  width:  100%;
  height:  4px;
  z-index:  300;
`

function Item(props){
    var short = props.short
    if(short !== null){
        if(short.Type==="Quote"){
            return Quote(props)
        }
        if(short.Type==="Question"){
            return Question(props)
        }
        if(short.Type==="Insight"){
            return Insight(props)
        }
        if(short.Type==="Story"){
            return Story(props)
        }
        if(short.Type==="Image"){
            return ImageStory(props)
        }
    }
    return <div></div>
}

function ImageQuote(props) {

}


function Quote(props) {
    const short = props.short
    return(
        <div>
        <ShortHeader>
            <GoQuote/>
                <QuoteTitle>
                    {short.Title}
                </QuoteTitle>
        </ShortHeader>
        <ShortDetails>
            <Statuses>{ (props.id === props.sectionPlaying && !props.loading) ? <PlayingIcon/> : null}</Statuses>
            <Details>{short.Details}</Details>
            <PlayShortStyled><PlayShort playAudio={props.playAudio} shortId={props.id} stopAudio={props.stopAudio} sectionPlaying={props.sectionPlaying} duration={short.End-short.Start} loading={props.loading}/>
            </PlayShortStyled>
        </ShortDetails>
        </div>
    )
}

function Insight(props) {
    const short = props.short
    return(
        <div>
        <ShortHeader>
        <InsightTitle>
        {short.Title}
        </InsightTitle>
        </ShortHeader>
        <ShortDetails>
            <Statuses>{ (props.id === props.sectionPlaying && !props.loading) ? <PlayingIcon/> : null}</Statuses>
            <InsightDetails>{short.Details}</InsightDetails>
            <PlayShortStyled><PlayShort playAudio={props.playAudio} shortId={props.id} stopAudio={props.stopAudio} sectionPlaying={props.sectionPlaying} duration={short.End-short.Start} loading={props.loading}/>
            </PlayShortStyled>
        </ShortDetails>
        </div>
    )
}

function ImageStory(props) {
    const short = props.short
    return(
        <ImageContainer ImageUrl={short.ImageUrl}>
            <ImageHeader>
                <ImageDuration><small><Duration duration={short.End-short.Start}/></small></ImageDuration>
                <ImagePlayStatus>
                    {(props.id === props.sectionPlaying && !props.loading) ? <PlayingIcon/> : null}
                </ImagePlayStatus>
            </ImageHeader>
            <ImageFooter>
                <ImageTitle>{short.Title}</ImageTitle>
                <ImagePlayContainer>
                    <PlayShort playAudio={props.playAudio} shortId={props.id} stopAudio={props.stopAudio} sectionPlaying={props.sectionPlaying} duration={short.End-short.Start} loading={props.loading}/>
                </ImagePlayContainer>
            </ImageFooter>
        </ImageContainer>
    )
}

const ImageContainer = styled.div`
display:grid;
background-image: url("${props => props.ImageUrl}");
background-size: cover;
height: 300px;
border-radius: 30px;
margin: 20px;
// grid-template-rows: .8fr .2fr;

`
const ImageHeader = styled.div`
grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 1;
display:grid;
grid-template-columns: .5fr .5fr;
`
const ImageDuration = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 1;
grid-column-end: 2;
text-align: left;
padding: 20px;
small{
    background: grey;
    border-radius: 10px;
    padding: 3px 5px 3px 5px;
}
`
const ImagePlayStatus = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 2;
grid-column-end: 2;
svg{
    height: 50px;
    float: right;
    padding: 20px;
}
`
const ImageFooter = styled.div`
grid-row-start: 2;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 1;
display:grid;
grid-template-columns: .75fr .25fr;
opacity: 70%;
margin-top: 150px;
background-color: black;
border-bottom-left-radius: 30px;
border-bottom-right-radius: 30px;
`
const ImageTitle = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 1;
grid-column-end: 2;
text-align: left;
padding: 10px;
font-size: 1.2em;
`
const ImagePlayContainer = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 2;
grid-column-end: 2;
align-self: center;
`

function Question(props) {
    const short = props.short
    return(
        <div>
        <ShortHeader>
            <QuestionTitle>{short.Title}</QuestionTitle>
        </ShortHeader>
        <ShortDetails>
            <Statuses>{ (props.id === props.sectionPlaying && !props.loading) ? <PlayingIcon/> : null}</Statuses>
            <Details>{short.Details}</Details>
            <PlayShortStyled><PlayShort playAudio={props.playAudio} shortId={props.id} stopAudio={props.stopAudio} sectionPlaying={props.sectionPlaying} duration={short.End-short.Start} loading={props.loading}/>
            </PlayShortStyled>
        </ShortDetails>
        </div>
    )
}

function Story(props) {
    const short = props.short
    return(
        <div>
        <ShortHeader>
            <StoryTitle>{short.Title}</StoryTitle>
        </ShortHeader>
        <ShortDetails>
            <Statuses>{ (props.id === props.sectionPlaying && !props.loading) ? <PlayingIcon/> : null}</Statuses>
            <Details>{short.Details}</Details>
            <PlayShortStyled><PlayShort playAudio={props.playAudio} shortId={props.id} stopAudio={props.stopAudio} sectionPlaying={props.sectionPlaying} duration={short.End-short.Start} loading={props.loading}/>
            </PlayShortStyled>
        </ShortDetails>
        </div>
    )
}

function PlayingIcon()
{
    return(
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M 1,10 v-7" pathLength="10" class="playing path1"/>
        <path d="M 4,10 v-8" pathLength="10" class="playing path2"/>
        <path d="M 7,10 v-10" pathLength="10" class="playing path3"/>
        <path d="M 10,10 v-8" pathLength="10" class="playing path4"/>
          </svg>
    )
}

const DurationStyled = styled.div`
font-size:small;
opacity: 50%;
`

function PlayShort(props){
    return(
        <div>
        <PlayIcon>
        { props.shortId === props.sectionPlaying ? <BsStop onClick={props.stopAudio} id="stop_snap" data-shortid={props.shortId}></BsStop> : <BsPlay onClick={() => props.playAudio(props.shortId)} id="play_snap" data-shortid={props.shortId}></BsPlay>}
        </PlayIcon>
        <ShortDurationStyled>
        { props.loading ? <p>Loading...</p> :
        <Duration duration={props.duration}/>}
        </ShortDurationStyled>
        </div>
    )
}

const ShortDurationStyled = styled.div`
grid-row-start: 2;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 1;
font-size: small;
`

const PlayIcon = styled.div`
grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 1;
font-size: small;
svg{
    background: #383d48;
    border-radius: 50%;
    padding: 5px;
    padding: 5px 5px 5px 7px;
    font-size : 2rem;
}
`
function ShortCard(props) {
    return (
        <ShortCardStyled>
            <ShortHeader>
            {props.ShortId}
            </ShortHeader>
            {/**
                <ShortMessage>{props.Short.message}</ShortMessage>
                <div onClick={props.handleUpvote}>Up</div>
                <div onClick={props.handleDownvote}>Down</div>
                <div>{props.Short.upvote-props.Short.downvote}</div>
            */}
            <ShortMessage>
            Message
            </ShortMessage>
            <ShortFooterContainer>
                <ShortCurate>
                    <div onClick={props.handleReplyShort}>Upvote</div>
                </ShortCurate>
                <ShortResponse>
                    <div onClick={props.handleReplyShort}><BsReplyFill /></div>
                </ShortResponse>
            </ShortFooterContainer>
        </ShortCardStyled>
    )
}



const Spacer = styled.div`
height:200px;
`

const Statuses = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 1;
grid-column-end: 2;
width:35px;
`

const InsightDetails = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 2;
grid-column-end: 3;

font-family: Georgia, Cambria, "Times New Roman", Times, serif;
letter-spacing: .03em;
font-style: normal;
font-weight: 400;
max-width: 75vw;

max-height: 100px;
overflow: hidden;
opacity: 50%;

overflow-wrap: anywhere;
text-align: left;
border-left-style: solid;
    border-radius: 2px;
    padding-left: 0.8rem;
    border-left-color: grey;

`

const Details = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 2;
grid-column-end: 3;

font-family: Georgia, Cambria, "Times New Roman", Times, serif;
letter-spacing: .03em;
font-style: normal;
font-weight: 400;
max-width: 75vw;

max-height: 100px;
overflow: hidden;
opacity: 50%;

overflow-wrap: anywhere;
text-align: left;
`

const PlayShortStyled = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 3;
grid-column-end: 4;
display: grid;

font-size: xxx-large;
opacity: 60%;
text-align: center;
svg{
    font-size:2rem;
}

`

const HeaderContainerStyled = styled.div`
// background:blue;
width:100vw;
display: grid;
// grid-template-columns: .33fr .66fr;
background: -webkit-linear-gradient(left, #25c481, #25b7c4);
    background: linear-gradient(to right, #25c481, #25b7c4);
`

const QuoteTitle = styled.div`
font-family: Georgia, Cambria, "Times New Roman", Times, serif;
letter-spacing: .03em;
font-style: normal;
font-weight: 400;

font-size: x-large;
padding: 10px 10px 10px 30px;
text-align: left;
font-style: italic;

grid-row-start: 1;
grid-row-end: 2;
grid-column-start: 1;
grid-column-end: 4;
`

const QuoteDetails = styled.div`
line-height: 32px;
`

const InsightTitle = styled.div`
font-family: Georgia, Cambria, "Times New Roman", Times, serif;
letter-spacing: .03em;
font-style: normal;
font-weight: 400;

font-size: x-large;
padding: 10px 10px 10px 30px;
text-align: left;
`
const QuestionTitle = styled.div`
font-family: Georgia, Cambria, "Times New Roman", Times, serif;
letter-spacing: .03em;
font-style: normal;
font-weight: 400;

font-size: x-large;
padding: 5px 10px 10px 30px;
text-align: left;
font-style: italic;
`
const StoryTitle = styled.div`
font-family: Georgia, Cambria, "Times New Roman", Times, serif;
letter-spacing: .03em;
font-style: normal;
font-weight: 400;

font-size: x-large;
padding: 30px 10px 10px 30px;
text-align: left;
`

const ShortHeader = styled.div`
grid-row-start: 1;
grid-row-end: 1;
grid-column-start: 1;
grid-column-end: 2;
text-align: left;

svg{
    padding: 10px 10px 0 10px;
    font-size: 2rem;
}
`;

const ShortMessage = styled.div`
grid-row-start: 2;
grid-row-end: 3;
grid-column-start: 1;
grid-column-end: 2;
`;

const ShortFooterContainer = styled.div`
    display: grid;
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
`

const ShortDetails = styled.div`
display: grid;
width: 100%;
grid-template-columns: .1fr .7fr .2fr;
`

const ShortCurate = styled.div`
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
`
const ShortResponse = styled.div`
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
`

const ShortContainerStyled = styled.div`

`


const ShortCardStyled = styled.div`
display: grid;
grid-template-columns: auto;
grid-gap: 10px;
background-color: #2196F3;
padding: 10px;
`